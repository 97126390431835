import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import axios from "axios";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import Loading from "./components/Loading";

const clientId =
  "674216813780-7a4860jb0evog7b89l4erfe6s6n5go6p.apps.googleusercontent.com";

function App() {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");

  const [table, setTable] = useState("Popular");
  const tableRef = useRef("Popular");

  const checkGId = (gId) => {
    setLoading(true);
    if (gId !== "") {
      axios
        .post(
          "https://3f9sjtekk9.execute-api.ca-central-1.amazonaws.com/prod/cg",
          JSON.stringify({ gid: gId }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setLoggedIn(true);
        })
        .catch((error) => {
          setLoggedIn(false);
        })
        .then(() => setLoading(false));
    }
  };

  const logout = () => {
    setLoggedIn(false);
  };

  const successIn = (response) => {
    setUsername(response.profileObj.email);
    checkGId(response.profileObj.email);
  };

  const failedIn = (response) => {};

  const getData = () => {
    setLoading(true);
    axios
      .get(
        `https://2xyiofqht3.execute-api.ca-central-1.amazonaws.com/get-mysql?Table=${tableRef.current}`
      )
      .then((res) => {
        setRecords(res.data.articles);
      })
      .catch((error) => console.log(""))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleTableChange = () => {
    if (table === "Popular") {
      setTable("PopularAll");
      tableRef.current = "PopularAll";
    } else {
      setTable("Popular");
      tableRef.current = "Popular";
    }

    getData();
  };

  return loggedIn ? (
    <div className="App container">
      <GoogleLogout
        clientId={clientId}
        render={(renderProps) => (
          <button
            className="button is-info is-small"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            {`Logout, ${username}`}
          </button>
        )}
        onLogoutSuccess={logout}
      ></GoogleLogout>

      <br />
      <br />

      <h3 className="title">
        {`Top ${records.length} Articles ${
          table === "Popular" ? "of the Week" : "All Time"
        }`}
      </h3>

      <button
        onClick={handleTableChange}
        className="button is-primary"
        style={{ marginBottom: "20px" }}
      >
        {`Switch to ${table === "Popular" ? "All Time" : "Current Week"}`}
      </button>

      <button
        onClick={getData}
        className={`button is-link  is-small is-fullwidth ${
          loading ? "is-loading" : ""
        }`}
      >
        Refresh
      </button>

      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Visits</th>
          </tr>
        </thead>
        <tbody>
          {records.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.title}
                </a>
              </td>
              <td>{item.visits}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div
      className="has-text-centered"
      style={{
        width: "640px",
        maxWidth: "100%",
        margin: "0 auto",
        borderLeft: "1px solid #e6d2d1",
        borderRight: "1px solid #e6d2d1",
      }}
    >
      <img
        src="https://res.cloudinary.com/thegauntlet/image/upload/v1609536667/TheG_Header_3_lxn2gl.jpg"
        style={{ display: "block" }}
      />
      <p style={{ marginBottom: "16px", paddingTop: "30px" }}>
        <img
          src="https://res.cloudinary.com/thegauntlet/image/upload/v1609532701/GauntletLetterhead_Grey_dh9afz.png"
          style={{ width: "256px" }}
        />
      </p>
      <GoogleLogin
        clientId={clientId}
        buttonText="Login with Google"
        onSuccess={successIn}
        onFailure={failedIn}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
        className="google-login-btn"
      />
      <br />
      {loading ? <Loading /> : null}
    </div>

    // <div className="container has-text-centered">
    //   <GoogleLogin
    //     clientId={clientId}
    //     buttonText="Login with Google"
    //     onSuccess={successIn}
    //     onFailure={failedIn}
    //     cookiePolicy={"single_host_origin"}
    //     isSignedIn={true}
    //     className="google-login-btn"
    //   />
    // </div>
  );
}

export default App;
